<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-row>
          <!-- locale -->
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="Language"
              rules="required"
            >
              <b-form-group
                label="language"
                label-for="language"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="language"
                  v-model="languagSelect"
                  :state="languagSelect === null ? false : true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="languageOptions"
                  :selectable="
                    (option) => !option.value.includes('select_value')
                  "
                  label="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- name -->
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-group label="Name" label-for="name">
                <b-form-input
                  id="TranslationName"
                  v-model="translations.name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- short_description -->
          <b-col md="4">
            <b-form-group
              label="Short Description"
              label-for="short-description"
            >
              <validation-provider
                #default="{ errors }"
                name="short-description"
                rules="required"
              >
                <b-form-input
                  id="short-description"
                  v-model="translations.short_description"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- description -->
          <b-col md="12">
            <b-form-group label="Description" label-for="description">
              <validation-provider
                #default="{ errors }"
                name="description"
                rules="required"
              >
                <vue-editor
                  id="translationDescription"
                  v-model="translations.description"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
          <b-col>
            <b-button
              size="lg"
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { required, integer } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
export default {
  components: {
    VueEditor,
  },
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      id: 0,
      itemID: 0,
      languagSelect: {
        text: '',
        value: '',
      },
      languageOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      translations: {
        locale: '',
        name: '',
        description: '',
        short_description: '',
      },
      required,
      integer,
    }
  },
  created() {
    this.id = this.$route.params.id
    axios
      .get('items-guide/translations/' + this.id + '/show')
      .then((result) => {
        const data = result.data.data
        this.translations.name = data.name
        this.translations.description = data.description
        this.translations.short_description = data.short_description
        if (data.locale == 'en') {
          this.languagSelect.text = 'English'
          this.languagSelect.value = 'en'
        } else {
          this.languagSelect.text = 'Arabic'
          this.languagSelect.value = 'ar'
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.translations.locale = this.languagSelect.value
          axios
            .put(
              'items-guide/translations/' + this.id + '/update',
              this.translations
            )
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
 

<style lang="scss" scoped>
 
</style>
